@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@page-banner-cls: ~'@{ra-prefix}-page-banner';
@page-banner-section-cls: ~'@{page-banner-cls}-section';
@page-banner-section-dark-cls: ~'@{page-banner-section-cls}-dark';
@page-banner-section-white-cls: ~'@{page-banner-section-cls}-white';
@page-banner-content-cls: ~'@{page-banner-cls}-content';
@page-banner-text-cls: ~'@{page-banner-cls}-text';
@page-banner-media-cls: ~'@{page-banner-cls}-media';
@page-banner-bottom-cls: ~'@{page-banner-cls}-bottom';
@page-banner-footer-cls: ~'@{page-banner-cls}-footer';
@page-banner-lazyload-cls: ~'@{page-banner-cls}-lazyload';

:export {
  pageBannerCls: @page-banner-cls;
  pageBannerSectionCls: @page-banner-section-cls;
  pageBannerSectionDarkCls: @page-banner-section-dark-cls;
  pageBannerSectionWhiteCls: @page-banner-section-white-cls;
  pageBannerContentCls: @page-banner-content-cls;
  pageBannerTextCls: @page-banner-text-cls;
  pageBannerMediaCls: @page-banner-media-cls;
  pageBannerBottomCls: @page-banner-bottom-cls;
  pageBannerFooterCls: @page-banner-footer-cls;
  pageBannerLazyloadCls: @page-banner-lazyload-cls;
}

.@{page-banner-section-cls} {
  &.@{page-banner-section-dark-cls} {
    background-color: @ra-color-grayscale-60;
    color: @ra-color-white;

    .ra-shortcut-links {
      .ra-link {
        &:hover {
          background-color: @ra-color-primary;
        }
      }
    }
  }

  &.@{page-banner-section-white-cls} {
    background-color: @ra-color-white;
  }

  .@{page-banner-lazyload-cls} {
    @media only screen and (min-width: @ra-screen-sm) {
      width: 100%;
      height: 100%;
    }
  }

  .@{page-banner-cls} {
    .@{page-banner-content-cls} {
      text-align: center;
      padding: 40px 0;

      @media only screen and (min-width: @ra-screen-sm) {
        text-align: left;
        padding: 0;
      }

      @media only screen and (min-width: @ra-screen-md) {
        margin: 0;
        height: 460px;
      }

      @media only screen and (min-width: @ra-screen-lg) {
        margin: 0;
        height: 500px;
      }

      .@{page-banner-text-cls} {
        align-self: center;
        margin: 0;

        @media only screen and (min-width: @ra-screen-sm) {
          margin: 0 70px 0 0;
        }

        @media only screen and (max-width: @ra-screen-xs-max) {
          padding: 0 @ra-wrapper-horizontal-pad-xs;
        }
      }

      .@{page-banner-media-cls} img,
      video.@{page-banner-media-cls} {
        @media only screen and (max-width: @ra-screen-xs-max) {
          display: block;
          width: 100%;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: 20px 0;
        }
      }
    }

    .@{page-banner-bottom-cls} {
      .@{page-banner-footer-cls} {
        margin-top: 20px;

        .ra-shortcut-links {
          margin-top: 15px;
          margin-bottom: 0;

          .ra-shortcut-links-container {
            padding: 0 15px;

            @media only screen and (min-width: @ra-screen-sm) {
              padding: 0;
              justify-content: start;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}
